import { MouseEvent } from "react";

import { useBotOptions } from "../../Context/BotOptions";

import "./ChatBotHeader.css";
import ServiceAgent from '../../assets/customer-service-agent.png';
import closeChatIcon from "../../assets/close_chat_icon.svg";
import { ChatViewActionContextInfo, ChatViewContextInfo } from "../../Context/ChatContextProvider";
import { Inviter, SessionState } from "sip.js";

const ChatBotHeader = () => {
  // handles options for bot
  const { botOptions, setBotOptions,setCall } = useBotOptions();
  const { callToken} = ChatViewContextInfo();
  const {setCallToken} = ChatViewActionContextInfo();
  const hangUp = () => {
    if (callToken) {
      const sessionState = callToken.state;
  
      if (sessionState === SessionState.Terminated) {
        console.error('Call has already been terminated.');
        setCallToken(null)
        return;  // Exit early if the session is already terminated
      }
  
      // Check if the call is in progress or still being established
      if (sessionState === SessionState.Establishing && callToken instanceof Inviter) {
        // Cancel the call if it's in the Establishing state
        callToken.cancel().then(() => {
          setCallToken(null)
        }).catch((error: any) => {
          console.error('Failed to cancel the call:', error);

        });
      } else if (sessionState === SessionState.Established) {
        // Hang up the call if it's already established
        callToken.bye().then(() => {
          setCallToken(null)
        }).catch((error: any) => {

        });
      } else {
        console.error('Invalid session state:', sessionState);
      }
    } else {
      console.error('No active session to hang up.');
   
    }
  }
  // styles for header
  const headerStyle: React.CSSProperties = {
    background: `linear-gradient(to right, ${botOptions.theme?.secondaryColor},
			${botOptions.theme?.primaryColor})`,
    ...botOptions.headerStyle,
  };

  // icons in header
  const headerImages = {
    headerAvatar: {
      backgroundImage: `url(${ServiceAgent})`,
    },
    notificationIcon: {
      backgroundImage: `url(${botOptions.notification?.icon})`,
    },
    audioIcon: {
      backgroundImage: `url(${botOptions.audio?.icon})`,
    },
    closeChatIcon: {
      backgroundImage: `url(${closeChatIcon})`,
      top:'10px'

    },
  };

  /**
   * Handles closing of chat window.
   */
  const handleCloseChat = () => {
    hangUp()
	setCall(false)

    // setBotOptions({ ...botOptions, isOpen: false });
  };

  return (
    <div style={headerStyle} className="rcb-chat-header-container">
      <div className="rcb-chat-header">
        {botOptions.botBubble?.showAvatar && (
          <div style={headerImages.headerAvatar} className="rcb-bot-avatar" />
        )}
        {botOptions.header?.title}
      </div>
      <div className="rcb-chat-header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5,
          }}
        >
          <div style={{ cursor: "pointer", display: "flex" }}>
            <span style={{ margin: 0, fontSize: 19, fontWeight: "bold" }}>
              CFSS Customer Support
            </span>
          </div>
          {/* <span
            className="material-symbols-outlined more-vert"
            style={{ fontSize: "1.5rem", cursor: "pointer", paddingLeft: 10 }}
          >
            more_vert
          </span> */}
          {/* <div className="dropdown-options" ref={dropdownOption}>
              <a href="#" onClick={endChat}>End Chat</a>
            </div> */}
        </div>
        <div
          style={headerImages.closeChatIcon}
          onMouseDown={(event: MouseEvent) => {
            event.stopPropagation();
            handleCloseChat();
          }}
          className="rcb-close-chat-icon"
        ></div>
      </div>
    </div>
  );
};

export default ChatBotHeader;
