import axios from "axios";
import { useEffect, useRef } from "react";
import { ChatViewActionContextInfo, ChatViewContextInfo } from "../../Context/ChatContextProvider";
import { Inviter, SessionState } from "sip.js";
import { environment } from "../../environments/environment.dev";

const CloseModal = () => {
    const APIURL = environment.APIURL
    const dropdownOption = useRef<any>(null);
    const {setIsDeleted,setAccessToken,setsipAgent,setsipReg,setJobID} = ChatViewActionContextInfo();
    const { accessToken, customerID,isDeleted,threadId, sipAgent,JobID,
      sipReg,} = ChatViewContextInfo();
    // const  = 
    const toggleDropdown =()=> {
        // const dropdown = document.getElementById('dropdownOptions');
        if(dropdownOption){
        dropdownOption.current.style.display = dropdownOption.current.style.display === 'block' ? 'none' : 'block';
        }
      }
      const hoverDropdown =(style: any)=> {
        // const dropdown = document.getElementById('dropdownOptions');
        if(dropdownOption){
        dropdownOption.current.style.display = dropdownOption.current.style.display === style ? style : style;
        }
      }
    const endChat1 = async () => {
       // Hang up the call if it's already established
       setIsDeleted(true)
       return
       accessToken.bye().then(() => {
        setIsDeleted(true)
        accessToken?.dispose()
        setAccessToken(null)
        setsipAgent(null)
        setsipReg(null)
        alert("hi")
             }).catch((error: any) => {
               console.error('Failed to hang up the call:', error);
             
             });
             return
      if (accessToken) {
        const sessionState = accessToken.state;
         console.log(sessionState,"sessionState");
         
        if (sessionState === SessionState.Terminated) {
          console.error('Call has already been terminated.');
          return;  // Exit early if the session is already terminated
        }
    
        // Check if the call is in progress or still being established
        if (sessionState === SessionState.Establishing && accessToken instanceof Inviter) {
          // Cancel the call if it's in the Establishing state
          accessToken.cancel().then(() => {
      
          }).catch((error: any) => {
            console.error('Failed to cancel the call:', error);
       
          });
        } else if (sessionState === SessionState.Established) {
          // Hang up the call if it's already established
          accessToken.bye().then(() => {
     alert("hi")
          }).catch((error: any) => {
            console.error('Failed to hang up the call:', error);
          
          });
        } else {
          console.error('Invalid session state:', sessionState);
        }
      
      } 
      if(sipAgent ||sipReg ){
        if (sipReg) {
          console.log(sipReg);
          
          //sipReg.unregister();
        }
        // if (sipAgent) {
        //   console.log(sipAgent);
        //   sipAgent.stop().then(() => {
        //     console.log('User Agent stopped');
        //     console.log("hi")
      
        //   }).catch((error:any) => {
        //     console.error('Failed to stop User Agent:', error);
        //   });
        // }
      }
       setIsDeleted(true)
       setAccessToken(null)
       setsipAgent(null)
       setsipReg(null)
  //  if(accessToken){
  //   accessToken.bye().then(() => { ;
          
  //   }).catch((error: any) => {
  //         console.error('Failed to hang up the call:', error);
        
  //       });
  //  }
      
              return
      
      }

      const endChat = async () =>{
        const payload =
      {
       JobId: JobID,
       EndBy:"customer"
      }
        axios
        .post(
          APIURL +"CX/EndChat", payload
        )
        .then(
          async (response) => {
           const data = response
           console.log(data,"data")
           setIsDeleted(true)
           setAccessToken(null)
           setsipAgent(null)
           setsipReg(null)
           setJobID(null)
        }
      )
        .catch((error) => {
        alert("error")
        });
      }
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center",paddingTop:5}}>
            <div style={{cursor: "pointer",display:"flex",justifyContent:"space-between"}}>
              <span style={{marginRight: 10,fontSize: 15.5, fontWeight: "bold"}}>CFSS Customer Support</span> 
            {JobID ? <button onClick={ endChat} style={{marginLeft:"10px",background:"red",color:"white",borderRadius:"3px",height:"25px",fontSize:8,width:'50px',cursor:"pointer",border:"1.6px solid #fff",fontWeight:"bold"}}>End Chat</button> : null}
           
        </div>
        
        {/* <span className="material-symbols-outlined more-vert" style={{fontSize:'1.5rem', cursor: 'pointer',paddingLeft:10}} onMouseEnter={()=>hoverDropdown("block")}   onClick={toggleDropdown}>more_vert</span>
            <div className="dropdown-options" ref={dropdownOption}>
              <a href="#" onClick={endChat}  onMouseLeave={()=>hoverDropdown("none")}>End Chat</a>
            </div> */}

            
        </div>





  );
};

export default CloseModal;
