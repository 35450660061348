import { Options } from "../types/Options";

// import chatButton from "../assets/chat_button.png";
// import actionDisabledIcon from "../assets/action_disabled_icon.png";
// import botAvatar from "../assets/bot_avatar.png";
import userAvatar from "../assets/user_avatar.svg";
import chatIcon from "../assets/chat-forum.png";
// import fileAttachmentIcon from "../assets/file_attachment_icon.svg";
// import notificationIcon from "../assets/notification_icon.svg";
// import closeChatIcon from "../assets/minimize.png";
// import sendButtonIcon from "../assets/send_icon.svg";
// import voiceIcon from "../assets/voice_icon.svg";
// import emojiIcon from "../assets/emoji_icon.svg";
// import audioIcon from "../assets/audio_icon.svg";
// import notificationSound from "../assets/notification_sound.wav";
import ServiceAgent from '../assets/customer-service-agent.png'

// default options provided to the bot
export const defaultOptions = {
	// tracks state of chat window, also the default state to load it in
	isOpen: false,

	// configurations
	theme: {
		primaryColor: "#42b0c5",
		secondaryColor: 'rgb(66, 176, 197)',
		fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', " +
			"'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', " +
			"sans-serif",
		showHeader: true,
		showFooter: true,
		showInputRow: true,
		// actionDisabledIcon: actionDisabledIcon,
		embedded: false,
		desktopEnabled: true,
		mobileEnabled: true,
		flowStartTrigger: "ON_LOAD",
	},
	tooltip: {
		mode: "NEVER",
		text: "Hey How can i help you! 😊",
	},
	chatButton: {
		icon: chatIcon,
	},
	// header: {
		// title: '',
		// showAvatar: true,
		// avatar: ServiceAgent,
		// closeChatIcon: closeChatIcon,
	// },
	notification: {
		disabled: true,
		defaultToggledOn: false,
		// volume: 0.2,
		// icon: notificationIcon,
		// sound: '',
		showCount: false,
	},
	// audio: {
	// 	disabled: true,
	// 	defaultToggledOn: false,
	// 	language: "en-US",
	// 	voiceNames: ["Microsoft David - English (United States)", "Alex (English - United States)"],
	// 	rate: 1,
	// 	volume: 1,
	// 	// icon: audioIcon,
	// },
	chatHistory: {
		disabled: true,
		maxEntries: 30,
		storageKey: "rcb-history",
		viewChatHistoryButtonText: "Load Chat History ⟳",
		chatHistoryLineBreakText: "----- Previous Chat History -----",
		autoLoad: false,
	},
	chatInput: {
		disabled: false,
		allowNewline: false,
		enabledPlaceholderText: "Type your message...",
		disabledPlaceholderText: "",
		showCharacterCount: false,
		characterLimit: -1,
		botDelay: 0,
		// sendButtonIcon: sendButtonIcon,
		blockSpam: true,
		sendOptionOutput: true,
		sendCheckboxOutput: true,
		sendAttachmentOutput: true,
	},
	chatWindow: {
		showScrollbar: false,
		autoJumpToBottom: true,
		showMessagePrompt: true,
		messagePromptText: "New Messages ↓",
		messagePromptOffset: 30,
	},
	sensitiveInput: {
		maskInTextArea: true,
		maskInUserBubble: true,
		asterisksCount: 10,
		hideInUserBubble: false,
	},
	userBubble: {
		animate: false,
		showAvatar: true,
		avatar: userAvatar,
		simStream: false,
		streamSpeed: 30,
		dangerouslySetInnerHtml: false,
	},
	botBubble: {
		animate: true,
		showAvatar: true,
		avatar: ServiceAgent,
		simStream: false,
		streamSpeed: 30,
		dangerouslySetInnerHtml: false,
	},
	// voice: {
	// 	disabled: true,
	// 	defaultToggledOn: false,
	// 	language: "en-US",
	// 	timeoutPeriod: 10000,
	// 	autoSendDisabled: false,
	// 	autoSendPeriod: 1000,
	// 	// icon: voiceIcon,
	// },
	footer: {
		text: (
			<div style={{cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", columnGap: 3}} 
				onClick={() => window.open("https://sbnasoftware.com/contact")}
			>
				{/* <span>Powered By</span> */}
				{/* <img style={{width: 10, height: 10}} src={chatButton}/> */}
				{/* <span style={{fontWeight: "bold"}}>SBNA Software Solutions</span> */}
			</div>
		),
	},
	fileAttachment: {
		disabled: true,
		multiple: true,
		accept: ".png",
		// icon: fileAttachmentIcon,
	},
	emoji: {
		disabled: true,
		// icon: emojiIcon,
		list: ["😀", "😃", "😄", "😅", "😊", "😌", "😇", "🙃", "🤣", "😍", "🥰", "🥳", "🎉", "🎈", "🚀", "⭐️"]
	},
	advance: {
		useCustomMessages: true,
		useCustomBotOptions: false,
		useCustomPaths: false,
	},

	// styles
	tooltipStyle: {},
	chatButtonStyle: {
        backgroundImage: 'none',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundColor: "transparent",
        // color: "white",
        borderRadius: "50%",
        width: 45,
        height: 45,
        bottom:'100px',
        right:'35px',
		opacity:0,
		boxShadow:'none',
		PointerEvent: 'none'
        // display:'none'
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // cursor: "pointer",
        // boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.75)",
    },
	notificationBadgeStyle: {},
	chatWindowStyle: {},
	headerStyle: {},
	bodyStyle: {},
	chatInputContainerStyle: {},
	chatInputAreaStyle: {},
	chatInputAreaFocusedStyle: {},
	chatInputAreaDisabledStyle: {},
	userBubbleStyle: {
		backgroundColor: 'rgb(73, 29, 141)',
    // color: 'rgb(255, 255, 255)',
    maxWidth: '70%',
	},
	botBubbleStyle: {
		backgroundColor: 'rgb(66, 176, 197)',
		// color: 'rgb(255, 255, 255)',
		maxWidth: '70%',
	},
	botOptionStyle: {},
	botOptionHoveredStyle: {},
	botCheckboxRowStyle: {},
	botCheckboxNextStyle: {},
	botCheckMarkStyle: {},
	botCheckMarkSelectedStyle: {},
	sendButtonStyle: {},
	sendButtonHoveredStyle: {},
	characterLimitStyle: {},
	characterLimitReachedStyle: {},
	chatHistoryButtonStyle: {},
	chatHistoryButtonHoveredStyle: {},
	chatHistoryLineBreakStyle: {},
	chatMessagePromptStyle: {},
	chatMessagePromptHoveredStyle: {},
	footerStyle: {},
	loadingSpinnerStyle: {},
}

/**
 * Retrieves default values for bot options.
 */
export const getDefaultBotOptions = () => {
	return defaultOptions;
}

/**
 * Parses default options with user provided options to generate final bot options.
 * 
 * @param providedOptions options provided by the user to the bot
 */
export const parseBotOptions = (providedOptions: Options | undefined) => {
	if (!providedOptions) {
		return defaultOptions;
	}

	// enforces value for bot delay does not go below 500
	if (providedOptions.chatInput?.botDelay != null && providedOptions.chatInput?.botDelay < 500) {
		providedOptions.chatInput.botDelay = 500;
	}

	return getCombinedOptions(providedOptions);
}

/**
 * Combines default and provided options.
 * 
 * @param providedOptions options provided by the user to the bot
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCombinedOptions = (providedOptions: any): Options => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mergedOptions: any = { ...defaultOptions };
	for (const prop in providedOptions) {
		if (typeof providedOptions[prop] === "object" && providedOptions[prop] !== null) {
			mergedOptions[prop] = { ...mergedOptions[prop], ...providedOptions[prop] };
		} else {
			mergedOptions[prop] = providedOptions[prop];
		}
	}
	return mergedOptions;
}