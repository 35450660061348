
import { useEffect, useRef, useState } from 'react';
import { UserAgent, Registerer, Inviter, Session, SessionState, RegistererState } from 'sip.js';
import RoundedIcon from '../Rounded-Icon';
import { MdCallEnd, MdDialpad, MdMicOff, MdOutlineMicNone } from 'react-icons/md';
import { useBotOptions } from '../../Context/BotOptions';
import axios from 'axios';
import CallComponent from '../Timer';
import { ChatViewActionContextInfo, ChatViewContextInfo } from '../../Context/ChatContextProvider';
import { environment } from '../../environments/environment.dev';
const CallWidgetBody = ({ setQueue }:{setQueue:(type:boolean)=> void}) =>  {
  let reconnectTimeout: NodeJS.Timeout | null = null;
  const mediaElementRef = useRef<HTMLAudioElement | null>(null);
  const userAgentRef = useRef<UserAgent | null | any>(null);
  const registererRef = useRef<Registerer | null>(null);
  const sessionRef = useRef<Session | null | any>(null);
  const [callStatus, setCallStatus] = useState<string>('Initializing...');
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState<boolean>(false);
  const [socket, setSocket] = useState<any | WebSocket>(null);
  const [isCallActive, SetCallactive] = useState(false)
  let webSocketURL = "";
  let SipAgent1 = "";
  let SipAgent2 = "";
  let userName = ""
  let password = ""

  const setupRemoteMedia = (session: Session | any) => {
    const mediaElement = mediaElementRef.current;
    if (!mediaElement) return;

    const remoteStream = new MediaStream();
    session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver: any) => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });

    mediaElement.srcObject = remoteStream;
    mediaElement.play().catch(console.error);
    console.log(isCallActive,"isCallActive")
  };

  const cleanupMedia = () => {
    const mediaElement = mediaElementRef.current;
    if (mediaElement) {
      mediaElement.srcObject = null;
      mediaElement.pause();
    }
  };
 
  const initializeSIPUserAgent = (socket: WebSocket) => {
    const userAgentOptions = {
     // uri: UserAgent.makeURI(`${SipAgent1};username=Madhesh;callerid=9500472842`),
      uri: UserAgent.makeURI(SipAgent1),
      transportOptions: {
        server: webSocketURL,
        connectionTimeout: 5000,
        traceSip: true,
        connection: socket,
        keepAliveInterval: 30, 
        reconnectInterval: 3000,
      },
      authorizationUsername: userName,
      authorizationPassword:password,
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {
          audio: true,
          video: false,
        },
        sessionTimersExpires: 60,
      },
      supported: ['timer'],
    };

    const userAgent = new UserAgent(userAgentOptions);
    userAgentRef.current = userAgent;

    const registerer = new Registerer(userAgent);
    registererRef.current = registerer;
    registerer.stateChange.on((newState) => {
      console.log(newState,"RegistererState");
      switch (newState) {
        case RegistererState.Registered:
          console.log("UserAgent is registered.");
          break;
        case RegistererState.Unregistered:
          console.log("UserAgent is unregistered.");
         // reRegisterUserAgent()
          break;
        case RegistererState.Terminated:
          console.log("UserAgent registration terminated.");
          break;
        default:
          console.log("UserAgent registration state:", newState);
      }
    });
    userAgent.start().then( async () => {
      console.log('User Agent started');
      return registerer.register();
    }).then( async () => {
      console.log('Registered successfully');
     if(callToken){
      setCallToken(null)
     }
     await cleanupMedia()
     await makeCall("hi")
      setCallStatus('Agent Registered');
    }).catch((error) => {
      console.error('Failed to start/register User Agent:', error);
      setCallStatus('Failed to register');
    });

    userAgent.delegate = {
      // onInvite: (invitation) => {

      //   sessionRef.current = invitation;
      //   setCallStatus('Incoming Call...');
      //   invitation.accept().then(() => {
      //     setupRemoteMedia(invitation);
      //     monitorSessionEvents(invitation)
      //     setCallStatus('Call Accepted');
      //   }).catch(console.error);
      // },
    };
    userAgent.transport.stateChange.addListener(async (newState) => {
      console.log('Transport state changed:', newState);
      if (newState === "Disconnected") {
        console.log("WebSocket connection lost.");
        if (isCallActive) {
          await reconnectWebSocket();
        }
      } else if (newState === "Connected") {
        console.log("WebSocket connected.");
      }
    });
    
  };
  const connectSockt = () => {
    const socket = new WebSocket(webSocketURL);

    socket.onopen = () => {
      console.log('WebSocket connection established');
      initializeSIPUserAgent(socket);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
      console.log(isCallActive,"isCallActive");
      if(isCallActive){
        retryWebscoket()
      }
     
    };
    setSocket(socket);
    return () => {
      if (registererRef.current) {
        registererRef.current.unregister();
      }
      if (userAgentRef.current) {
        userAgentRef.current.stop().then(() => {
          console.log('User Agent stopped');
          console.log("hi")
    
        }).catch((error:any) => {
          console.error('Failed to stop User Agent:', error);
        });
      }
      if (socket) {
        socket.close();
      }
    };
  }
  const retryWebscoket = () => {
    const socket = new WebSocket(webSocketURL);
     
    socket.onopen = () => {
      console.log('WebSocket connection established');
      reconnectWebSocket()
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
    setSocket(socket);
    
  }
  const reconnectWebSocket = async () => {
    try {
      if (userAgentRef.current && !userAgentRef.current.transport.isConnected()) {
        console.log("Reconnecting WebSocket...");
        await userAgentRef.current.transport.connect();
        console.log("WebSocket reconnected.");
      } else {
        console.log("WebSocket already connected or userAgent not initialized.");
      }
    } catch (error) {
      console.error("Error during WebSocket reconnection:", error);
    }
  };

  const DisconnectWebSocket = async () => {
    try {
      if (userAgentRef.current && userAgentRef.current.isConnected()) {
        console.log("Disconnecting WebSocket...");
        await userAgentRef.current.transport.disconnect();
        console.log("WebSocket disconnected.");
      } else {
        console.log("WebSocket already disconnected or not initialized.");
      }
    } catch (error) {
      console.error("Error during WebSocket disconnect:", error);
    }
    closeWebSocket()
  };

  const closeWebSocket = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close();
      console.log("WebSocket connection manually closed.");
    }
    if( sessionRef?.current) {
      sessionRef?.current.dispose()
    }
    
  };
  
  // useEffect(() => {
  //   if(isCallActive){
  //     if(navigator.onLine){
  //  alert("aa")
  //     }
  //     else {
  //       alert("bb")
  //     }
  //   }
  // }, [isCallActive])
  
  useEffect(() => {
   
    const handleOnline = () => {
   setCallStatus("Call Connected..")
    };

    const handleOffline = () => {
     
      setCallStatus("We’re experiencing poor call quality. Please ensure your network is stable.")
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isCallActive]);


  const { callToken} = ChatViewContextInfo();
  const {setCallToken} = ChatViewActionContextInfo();
  const [peer, setPeer] = useState<any>()
  const makeCall = (destination: string) => {
    const params:any = new URLSearchParams(window.location.search)
    let CFSS_WidgetInfo1:any=JSON.parse(params.get('CFSS_WidgetInfo'))
    let CFSS_WidgetInfo =  JSON.parse(CFSS_WidgetInfo1)
    let address = {}
    if(CFSS_WidgetInfo){
      address = {
       "apt": CFSS_WidgetInfo?.apt,
       "street": CFSS_WidgetInfo?.street,
       "city": CFSS_WidgetInfo?.city,
       "county": CFSS_WidgetInfo?.county,
       "zipCode": CFSS_WidgetInfo?.zipCode,
       "email": CFSS_WidgetInfo?.email,
       "phone1": CFSS_WidgetInfo?.phone1,
       "CustomerName": CFSS_WidgetInfo?.name,
       "userName": CFSS_WidgetInfo?.userName,
      }
    }

    const options = {
      requestDelegate: {
        onAccept: (response: any) => {
          setCallStatus('Call accepted');
        },
        onReject: (response: any) => {
          setCallStatus('Call rejected');
        }
      },
     // requestOptions: {
        extraHeaders: [
         'X-Custom-Header: Madhesh',
         `X-Customer-Address: ${JSON.stringify(address)}` ,
         'Session-Expires: 120'
        ]
        
      //}
    };
    
    
    if (!userAgentRef.current) return;

   //const targetURI = UserAgent.makeURI(`${SipAgent2};username=Madhesh;callerid=9500472842`);
  const targetURI = UserAgent.makeURI(SipAgent2);
    //const targetURI = UserAgent.makeURI(SipAgent2);
    if (!targetURI) {
      console.error('Invalid target URI');
      return;
    }
    const Offeroptions = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: {
            noiseSuppression: true, // Suppress background noise
            echoCancellation: true, // Prevent echoes
            autoGainControl: true,  // Automatically adjust microphone sensitivity
          },
          video: false,
        },
        offerOptions: {
        //  voiceActivityDetection: true, // Improve detection of speaking vs noise
       //   codecPreferences: ["opus/48000"], // Prioritize Opus codec
        },
      },
    };
    const inviter:any = new Inviter(userAgentRef.current, targetURI,options);
    
    sessionRef.current = inviter;
    console.log(sessionRef.current,"c")
    setCallStatus('Calling...');
    setCallToken(inviter)
    inviter.invite(Offeroptions).then((response:any) => {
      SetCallactive(true)
      setupRemoteMedia(inviter);
     console.log(inviter?.sessionDescriptionHandler?.peerConnection
      ,"inviter----------")
      setPeer(inviter?.sessionDescriptionHandler?.peerConnection)
      console.log('Call accepted with response:', response);
      const customHeader = response.message.getHeader('X-Custom-Header');
      console.log('Received custom header:', customHeader);
      setCallStatus('Call in Progress...');
 
    }).catch((error:any) => {
      console.error('Failed to make call:', error);
     // setCallStatus('Call Failed');
     SetCallactive(false)
      setCallStatus('Enable Microphone access to make call');
    });
  };
  useEffect(() => {
    peer?.getStats(null).then((stats:any) => {
      stats.forEach((report:any) => {
        if (report.type === "inbound-rtp" && report.kind === "audio") {
          console.log("Audio packets received:", report.packetsReceived);
          console.log("Audio packets lost:", report.packetsLost);
        }
        if (report.type === "inbound-rtp" && report.kind === "video") {
          console.log("Video packets received:", report.packetsReceived);
          console.log("Video packets lost:", report.packetsLost);
        }
      });
    });
    peer?.addEventListener("iceconnectionstatechange", () => {
      console.log("ICE Connection State:", peer?.iceConnectionState);
      if (peer.iceConnectionState === "disconnected") {
        console.warn("A peer has been disconnected. Check connectivity.");
        alert("We’re experiencing poor call quality. Please ensure your network is stable.")
        //alert("disconnected")
      } else if (peer.iceConnectionState === "failed") {
        console.error("ICE connection failed. Media flow may stop.");
      } else if (peer.iceConnectionState === "connected") {
        console.log("ICE connection is active.");

      }
    });
    peer?.addEventListener("connectionstatechange", () => {
      console.log("Connection state changed:", peer.connectionState);
  });
  
  peer?.addEventListener("icegatheringstatechange", () => {
      if (peer.iceGatheringState === "complete") {
          console.log("ICE gathering complete.");
      }
  });
  
  peer?.addEventListener("icecandidate", (event:any) => {
      if (event.candidate) {
          console.log("New ICE Candidate:", event.candidate);
      } else {
          console.log("All ICE candidates have been gathered.");
      }
  });
  
  
    
    
  }, [peer])




  // const hangUp = () => {
  //   if (sessionRef.current) {
  //     sessionRef.current.bye?.().then(() => {
  //       cleanupMedia();
  //       handleCloseCall()
  //       sessionRef.current = null;
  //       setCallStatus('Call Ended');
  //     }).catch((error:any) => {
  //       console.error('Failed to hang up:', error);
  //       setCallStatus('Failed to End Call');
  //     });
  //   }
  // };

  const hangUp = () => {
    if (sessionRef.current) {
      const sessionState = sessionRef.current.state;
  
      if (sessionState === SessionState.Terminated) {
        console.error('Call has already been terminated.');
        setCallStatus('Call already ended');
        cleanupMedia();
        handleCloseCall();
        sessionRef.current = null;
        return;  // Exit early if the session is already terminated
      }
  
      // Check if the call is in progress or still being established
      if (sessionState === SessionState.Establishing && sessionRef.current instanceof Inviter) {
        // Cancel the call if it's in the Establishing state
        sessionRef.current.cancel().then(() => {
          cleanupMedia();
          handleCloseCall();
          sessionRef.current = null;
          sessionRef.current.dispose()
          setCallStatus('Call Canceled');
        }).catch((error: any) => {
          console.error('Failed to cancel the call:', error);
          setCallStatus('Failed to Cancel Call');
        });
      } else if (sessionState === SessionState.Established) {
        // Hang up the call if it's already established
        sessionRef.current.bye().then(() => {
          sessionRef.current.dispose()
          cleanupMedia();
          handleCloseCall();
          sessionRef.current = null;
          setCallStatus('Call Ended');
        }).catch((error: any) => {
          console.error('Failed to hang up the call:', error);
          setCallStatus('Failed to End Call');
        });
      } else {
        console.error('Invalid session state:', sessionState);
      }
    
    } else {
      console.error('No active session to hang up.');
      setCallStatus('No active call');
      cleanupMedia();
      handleCloseCall();
      sessionRef.current = null;
    }
    cleanupMedia();
          handleCloseCall();
          sessionRef.current = null;
    if(registererRef.current ||userAgentRef.current ){
      if (registererRef.current) {
        registererRef.current.unregister();
      }
      if (userAgentRef.current) {
        userAgentRef.current.stop().then(() => {
          console.log('User Agent stopped');
          console.log("hi")
    
        }).catch((error:any) => {
          console.error('Failed to stop User Agent:', error);
        });
      }
    }
    SetCallactive(false)
    DisconnectWebSocket()
  };
  
  const { setCall } = useBotOptions();
useEffect(() => {
  getCutomerDetatils()
  const params = new URLSearchParams(window.location.search)
  console.log(params,"params")
  const allParamsArray = Array.from(params.entries());
allParamsArray.forEach(([key, value]) => {
console.log(`${key}: ${value}`);
});
 
}, [])


const handleCloseCall = () => {
  setCall(false)
    
  };
  const APIURL = environment.APIURL
  const getCutomerDetatils =  async () => {
    const params = new URLSearchParams(window.location.search)
    console.log(params,"params")
    const allParamsArray = Array.from(params.entries());
allParamsArray.forEach(([key, value]) => {
  console.log(`${key}: ${value}`);
});
    let IdentifierCode:any=params.get('IdentifierCode')
    let LoginUserId:any=params.get('LoginUserId')
    let IdentifierName:any=params.get('IdentifierName')
    //if(!WidgetInfo)  return alert("customer details not found")
  
   // if (apiCalled.current) return;  
 const URL = APIURL + "CX/getCustomerDetails"
  //const URL = "https://api.ccxai.in/CX/getCustomerDetails"
    //const data=  await axios.post(URL,{TenantId:"4d5aeb8d-94ec-42cd-8a55-3fdf86814613",CustomerId:"68808004-5000-48ab-8c82-fa170ffb2f2d",CustomerName:"madhesh"})
    const data=  await axios.post(URL,{UniqueCode:IdentifierCode,CustomerId:LoginUserId,CustomerName:IdentifierName,Channel:"call"})
    // const data=  await axios.post(URL,{UniqueCode:"ALLCAREUS_ALLCAREUS",CustomerId:"37",CustomerName:""})
    const responseDetails = await data.data.data
    console.log( data.data.message," data.data");
     console.log(data,"data");
     
    if(data.data.statusCode == 400){
      // abondedstatusChange()
      setCallStatus(data.data.message)
     }
     else { 
      console.log(data.data.statusCode)
      console.log(responseDetails,"responseDetails")
      const socket = "wss://" + responseDetails.TenantDomain + ":7443"
       /// const socket = "ws://" + responseDetails.TenantDomain + ":5066"
      const FromUri ="sip:"+responseDetails.CustomerUserId+"@"+responseDetails.TenantDomain
      const ToUri = "sip:"+responseDetails.TenantExtenstion+"@"+responseDetails.TenantDomain
      const authorizationUsername =  responseDetails.CustomerUserId
      const authorizationPassword = responseDetails.TenantCode + "_"+responseDetails.CustomerUserId
       webSocketURL = socket;
       SipAgent1 = FromUri;
       SipAgent2 = ToUri;
       userName = authorizationUsername
       password = responseDetails.Key
     // SetwebSocketURL(socket);
       const  extension = {
        socket:socket,
        FromUri:FromUri,
        ToUri:ToUri,
        authorizationUsername:authorizationUsername,
        authorizationPassword:authorizationPassword,
       }
       localStorage.setItem("Extension",JSON.stringify(extension))
     
      await connectSockt()
     }

   }
   const getTenantBasedQueue =  async () => {
    const params = new URLSearchParams(window.location.search)
    let IdentifierCode:any=params.get('IdentifierCode')
    let LoginUserId:any=params.get('LoginUserId')
    let IdentifierName:any=params.get('IdentifierName')
    //if(!WidgetInfo)  return alert("customer details not found")
  
   // if (apiCalled.current) return;  
  const URL = "https://api.sbnatech.in/CX/getTenantBasedQueue"
 // const URL = "https://api.ccxai.in/CX/getTenantBasedQueue"
    //const data=  await axios.post(URL,{TenantId:"4d5aeb8d-94ec-42cd-8a55-3fdf86814613",CustomerId:"68808004-5000-48ab-8c82-fa170ffb2f2d",CustomerName:"madhesh"})
    // const data=  await axios.post(URL,{UniqueCode:IdentifierCode,CustomerId:LoginUserId,CustomerName:IdentifierName})
     const data=  await axios.post(URL,{TenantCode:"ALLCAREUS_ALLCAREUS",})
    const responseDetails = await data.data.data
     console.log(responseDetails,"responseDetails");
     

   }
   const abondedstatusChange = async () => {
    const params = new URLSearchParams(window.location.search)
   
    let LoginUserId:any=params.get('LoginUserId')
    let IdentifierName:any=params.get('IdentifierName')
    let IdentifierCode:any=params.get('IdentifierCode')
      const URL = APIURL + "CX/abondedstatusChange"
    // const URL = "https://api.ccxai.in/CX/abondedstatusChange"

   const data=  await axios.post(URL,{callerId:LoginUserId + "_"+ IdentifierName,UniqueCode:IdentifierCode})
  // const data=  await axios.post(URL,{callerId:"37" + "_"+ "IdentifierName"})      
  // const data =  await axios.post(URL,{callerId:"68808004-5000-48ab-8c82-fa170ffb2f2d" + "_" + "Madhesh"}) 
   console.log(data,"data")
      const responseDetails = await data.data.data
      console.log(responseDetails,"responseDetails")
      getCutomerDetatils()

  }

  // const toggleMute = () => {
  //   setIsMuted(state => !state);
  //   console.log(isMuted)
  //   // Check if sessionRef.current and sessionDescriptionHandler exist
  //   if (!sessionRef.current || !sessionRef.current.sessionDescriptionHandler) {
  //     return false;
  //   }

  //   const pc:any = sessionRef.current.sessionDescriptionHandler.peerConnection;
  //   if (!pc) {
  //     return false;
  //   }

  //   const senders = pc.getSenders();

  //   senders.forEach((sender:any) => {
  //     if (sender.track?.kind === 'audio') {
  //       sender.track.enabled = isMuted;
  //     }
  //   });
  //   setCallStatus(isMuted ? 'Unmuted' : 'Muted');


  // };
 const [dtmfInput, setDtmfInput] = useState<any>(null)
  const sendDTMF = (tone: number, duration: number = 1000) => {
    console.log(tone)
    setDtmfInput(tone.toString())
    if (sessionRef.current && sessionRef.current.state === SessionState.Established) {
     
      const customDetails = {
        "key":1,
        "value":"Madhesh"
      }
      const dtmfOptions = {
        requestOptions: {
          headers: {
            "X-Custom-Key": "customDetails.key.toString()",
            "X-Custom-Value": "customDetails.value"
          },
          body: {
            contentDisposition: "render", // Tell the endpoint to render the DTMF tone
            contentType: "application/dtmf-relay", // Define it as a DTMF message
            content: `Signal=${tone}\r\nDuration=${duration} ${customDetails}`, // The DTMF signal and duration in ms
          },
        },
      };
  
      // Send the DTMF as a SIP INFO request
      sessionRef.current.info(dtmfOptions)
        .then(() => {
          console.log(`DTMF tone ${tone} sent successfully`);
          setCallStatus("Established")
        })
        .catch((error: any) => {
          console.error(`Failed to send DTMF tone ${tone}:`, error);
        });
    } else {
      console.error('Cannot send DTMF, session is not established or session is unavailable');
    }
  };
  
  
  
  const monitorSessionEvents = (session: Session | any) => {
    if(!session)return
    // Detect when the remote user hangs up
    session.delegate = {
      onBye: () => {
  //setCallStatus('Call Ended by Remote');
        //toast.error("Call Ended By Customer")
        setCallStatus('Call Ended by agent');
        cleanupMedia();
        handleCloseCall()
        SetCallactive(false)
        DisconnectWebSocket()
      },
      onTerminated: () => {
        //setCallStatus('Call Ended');
       // toast.error("Call Ended")
        cleanupMedia();
        handleCloseCall();
        SetCallactive(false)
        DisconnectWebSocket()
      },
    };
    session?.stateChange.addListener((state: SessionState)=>{
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          setCallStatus(SessionState.Establishing)
          break;
        case SessionState.Established:
         setCallStatus(SessionState.Established)
          break;
        case SessionState.Terminating:
          setCallStatus(SessionState.Terminating)
          // fall through
          break
        case SessionState.Terminated:
          setCallStatus(SessionState.Terminated)
          // fall through
          break;
        default:
          throw new Error("Unknown session state.");
    }
    })
  

    
  };
  
//   const getCutomerDetatils1 =  async () => {
//     const params = new URLSearchParams(window.location.search)
//     let TenantId:any=params.get('TenantId')
//    // if (apiCalled.current) return;  
//   const URL = "https://api.sbnatech.in/CX/getCustomerDetails"
//     const data=  await axios.post(URL,{TenantId:"4d5aeb8d-94ec-42cd-8a55-3fdf86814613"})
//     const responseDetails = await data.data.data
//     console.log(responseDetails,"responseDetails")
//     const socket = "wss://" + responseDetails.TenantDomain + ":7443"
//     const FromUri ="sip:"+responseDetails.CustomerUserId+"@"+responseDetails.TenantDomain
//     const ToUri = "sip:"+responseDetails.TenantExtenstion+"@"+responseDetails.TenantDomain
//     const authorizationUsername =  responseDetails.CustomerUserId
//     const authorizationPassword = responseDetails.TenantCode + "_"+responseDetails.CustomerUserId
//      webSocketURL = socket;
//      SipAgent1 = FromUri;
//      SipAgent2 = ToUri;
//      userName = authorizationUsername
//      password = authorizationPassword
//  //   CustomerRegistration(FromUri,authorizationUsername,authorizationPassword,ToUri);
//    // SetwebSocketURL(socket);
//      const  extension = {
//       socket:socket,
//       FromUri:FromUri,
//       ToUri:ToUri,
//       authorizationUsername:authorizationUsername,
//       authorizationPassword:authorizationPassword,
//      }
//      localStorage.setItem("Extension",JSON.stringify(extension))
//      connctSocket()
//    }



  const toggleMute = () => {
    setIsMuted(state => !state);
    console.log(isMuted)
    // Check if sessionRef.current and sessionDescriptionHandler exist
    if (!sessionRef.current || !sessionRef.current.sessionDescriptionHandler) {
      return false;
    }

    const pc:any = sessionRef.current.sessionDescriptionHandler.peerConnection;
    if (!pc) {
      return false;
    }

    const senders = pc.getSenders();

    senders.forEach((sender:any) => {
      if (sender.track?.kind === 'audio') {
        sender.track.enabled = isMuted;
      }
    });
  //  setCallStatus(isMuted ? 'Unmuted' : 'Muted');


  };
  const toggleHold = () => {
    if (sessionRef.current) {
      const senders = sessionRef.current.sessionDescriptionHandler.peerConnection.getSenders();
      senders.forEach((sender:any) => {
        if (sender.track) {
          sender.track.enabled = isOnHold;
        }
      });
      setIsOnHold(!isOnHold);
      setCallStatus(isOnHold ? 'Resumed' : 'On Hold');
    }
  };
  const getDTMF =()=>{

  }
  const [isDialpad, setisDialpad] = useState(false)
  const openDialPad = ()=>{

  }
const [open, setopen] = useState(false)
  const handleClose = () =>{
    setopen(!open)
  }
  const toggleDialpad = () => {
    if (!isDialpad) {
      // Only toggle dialpad if it doesn't interfere with the call
      setisDialpad(true);
    }
    else{
      setisDialpad(false);
    }
  };
 console.log(callStatus,"callStatus")
  useEffect(() => {
    monitorSessionEvents(sessionRef.current)
  }, [sessionRef.current])

    const img = "https://hds.hel.fi/images/foundation/visual-assets/placeholders/user-image-m@2x.png"
  const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  return (
    <>
   <div style={{
          display: "grid",
          gridTemplateRows: "1fr 80px",
          width: "100%",
          justifyContent: "center",
          backgroundColor: "white",
          padding: 10,
          borderRadius: 10,
          gap: 5
        }}>
          <div style={{ paddingBlock: 10, display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
            <audio ref={mediaElementRef} id="mediaElement" controls hidden />
            {img ? <img src={img} style={{ borderRadius: 30, height: 70, width: 70 }} /> : null}  
            <span style={{ fontWeight: "bolder", fontSize: 13, color: "grey" }}><CallComponent callStatus={callStatus} /></span>
            <span style={{ fontWeight: "bold", fontSize: 13, color: "#42b0c5" }}> {callStatus}</span>
          </div>
          {isCallActive ?  <div style={{ display: "grid", gridTemplateColumns: "1fr", width: "100%", gap: 25, height: 50 }}>
            <div onClick={toggleMute} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", pointerEvents: "auto" }}>
              <RoundedIcon active={isMuted} icon={isMuted ? <MdMicOff style={{ fontSize: 20 }} /> : <MdOutlineMicNone style={{ fontSize: 20 }} />} />
            </div>
          </div>: null}
         
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",gap:"10px" }}>
            <button onClick={hangUp} style={{ height: 35, width: 70, borderRadius: 20, backgroundColor: "#f1f1f1", border: "none", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 24, color: "red" }}>
              <MdCallEnd />
            </button>
            <button onClick={toggleDialpad} style={{ height: 35, width: 70, borderRadius: 20, backgroundColor: "#f1f1f1", border: "none", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 24, color: "black" }}>
              <MdDialpad />
            </button>
          </div>
        </div>
{isDialpad ? <div style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 0.6fr)",
          gap: "10px",
          maxWidth: "180px",
          margin: "auto",
          marginTop: "30px"
        }}>
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map(digit => (
            <button
              key={digit}
              onClick={() => {
                sendDTMF(parseInt(digit),parseInt(digit));
                // Optionally keep the dialpad open if needed
                // setisDialpad(false); 
              }}
              style={{
                padding: "10px",
                fontSize: "1.2em",
                borderRadius: "50%",
                background: dtmfInput === digit ?  "#00ced8cc" :"#42b0c5"  ,
                color: dtmfInput === digit ?  "white" :"black"  ,
                border: "none",
                cursor: "pointer",
                transition: "background 0.3s",
                boxShadow:
                dtmfInput === digit
                  ? "inset 0 4px 6px rgba(0, 0, 0, 0.3)" // Inner shadow for pressed effect
                  : "0 4px 6px rgba(0, 0, 0, 0.3)", // Default shadow
              transform: dtmfInput === digit ? "scale(0.9)" : "scale(1)", // Shrink slightly when pressed
              }}
            //  onMouseOver={(e) => { e.currentTarget.style.background = "#f0f0f0"; e.currentTarget.style.color = "black"; }}
             // onMouseOut={(e) => { e.currentTarget.style.background = "#42b0c5"; e.currentTarget.style.color = "white"; }}
             onMouseOver={(e:any) => (e.currentTarget.style.opacity = 0.8)}
onMouseOut={(e:any) => (e.currentTarget.style.opacity = 1)}

            >
              {digit}
            </button>
          ))}
        </div> : null}


    
     
     
    </>
   
  
   
  );
};

export default CallWidgetBody;





