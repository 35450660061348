import React, { useEffect, useRef, useState } from "react";
import "./Style.css";
import {
  ChatClient,
  ChatThreadClient,
  CreateChatThreadResult,
  SendMessageOptions,
} from "@azure/communication-chat";
import axios from "axios";
import { useBotOptions } from "../../Context/BotOptions";
import ChatBotTooltip from "../ChatBotTooltip/ChatBotTooltip";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { TransactionViewActionContextInfo } from "../../Context/TransactionContext";
import { json } from "stream/consumers";

var opts = {
  lines: 12, // The number of lines to draw
  length: 38, // The length of each line
  width: 17, // The line thickness
  radius: 39, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  speed: 1, // Rounds per second
  rotate: 0, // The rotation offset
  animation: "spinner-line-fade-quick", // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: "#ffffff", // CSS color or array of colors
  fadeColor: "transparent", // CSS color or array of colors
  top: "50%", // Top position relative to parent
  left: "50%", // Left position relative to parent
  shadow: "0 0 1px transparent", // Box-shadow for the lines
  zIndex: 2000000000, // The z-index (defaults to 2e9)
  className: "spinner", // The CSS class to assign to the spinner
  position: "absolute", // Element positioning
};

interface ChatWidgetIOProps {
  // apiKey: string;
  chatbotName?: string;
  isTypingMessage?: string;
  IncommingErrMsg?: string;
  primaryColor?: string;
  inputMsgPlaceholder?: string;
  chatIcon?: any;
  conversation: any;
  handleNewMessage: any;
  isSetChatOpen: (type: boolean) => void;
  // callOpen?: Boolean;
  chatClient: ChatClient | undefined;
  chatThreadClient: ChatThreadClient | undefined;
}

const ChatBotWidget = ({
  // apiKey,
  chatbotName = "UCX Support",
  isTypingMessage = "Typing...",
  IncommingErrMsg = "Oops! Something went wrong. Please try again.",
  primaryColor = "#eb4034",
  inputMsgPlaceholder = "Send a Message",
  // chatIcon = ChatIcon(),
  conversation,
  handleNewMessage,
  isSetChatOpen,
  // callOpen,
  chatClient,
  chatThreadClient,
}: ChatWidgetIOProps) => {
  const [hover, setHover] = useState(true);
  const onHover = () => {
    setHover(!hover);
  };

  const [isHide, isSetHide] = useState(true);
  const { botOptions, setBotOptions, setChatBot, showChatBot,setCall,showCall,setChatRegister } =
    useBotOptions();
  const [isLoad, setisLoad] = useState(false);
  const { setQueueList ,setCallQueue} = TransactionViewActionContextInfo();

  const toggleChatbot = async () => {
    if (!showChatBot) {
      setChatBot(true);
      setChatRegister(true)
    }
  };

  const closeBot = () => {
    setHover(true)
    document.body.classList.remove("show-chatbot");
  };
  useEffect(() => {

       //toggleLists()
  //  getCutomerDetatils()
     console.log("new Hi")
     }, []);
  const toggleLists = async () => {
    var url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href;
    console.log(window.location)

    const params = new URLSearchParams(window.location.search)
    let TenantId:any=params.get('TenantId')
    let LoginUserId:any=params.get('LoginUserId')
    let UniqueName:any=params.get('UniqueName')
    let WidgetInfo:any=params.get('widgetInfo')
    console.log(WidgetInfo)
    if (isHide) {
      isSetHide(false);
    } else {
      try {
        let params = new URLSearchParams();
        params.append("TenantId", 'fe9424aa-e286-4988-a58e-8ccf7ebf356d')
        setisLoad(true);
        const data = await axios.get(
          "https://ucx-dev.sbnasoftware.com/CX/getChannelBasedQueue?" + params
        );
        if (data.data.statusCode == 200) {
          console.log(data.data.data);
          const queueData = data.data.data.filter(
            (s: any) => s.ChannelName.toLowerCase() === "chat"
          );
          const callQueue = data.data.data.filter(
            (s: any) => s.ChannelName.toLowerCase() === "voice"
          );
          setQueueList(queueData);
          setCallQueue(callQueue);
        } else {
          setisLoad(false);
        }
      } catch (e) {
        console.log(e);
        setisLoad(false);
      }
    }
  };
  const apiCalled = useRef(false);
  const getCutomerDetatils =  async () => {
    const params = new URLSearchParams(window.location.search)
    let TenantId:any=params.get('TenantId')
    if (apiCalled.current) return;  
  const URL = "https://api.sbnatech.in/CX/getCustomerDetails"
    const data=  await axios.post(URL,{TenantId:"4d5aeb8d-94ec-42cd-8a55-3fdf86814613"})
    const responseDetails = await data.data.data
    console.log(responseDetails,"responseDetails")
    const socket = "wss://" + responseDetails.TenantDomain + ":7443"
    const FromUri ="sip:"+responseDetails.CustomerUserId+"@"+responseDetails.TenantDomain
    const ToUri = "sip:"+responseDetails.TenantExtenstion+"@"+responseDetails.TenantDomain
    const authorizationUsername =  responseDetails.CustomerUserId
    const authorizationPassword = responseDetails.TenantCode + "_"+responseDetails.CustomerUserId
   // SetwebSocketURL(socket);
     const  extension = {
      socket:socket,
      FromUri:FromUri,
      ToUri:ToUri,
      authorizationUsername:authorizationUsername,
      authorizationPassword:authorizationPassword,
     }
     localStorage.setItem("Extension",JSON.stringify(extension))
   }

  const toggleCall = ()=> {
    console.log("show")
    // if (!showCall) {
      setCall(!showCall);
    // }
  }
  return (
    <div
      className="chatbot-container"
      style={{
        background: primaryColor,
        backgroundColor: primaryColor,
      }}
    >

      {/* <button
        className="chatbot-toggler"
        onClick={toggleLists}
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        style={{ background: primaryColor }}
      >
        {!isHide ? (
          <>
            {" "}
            {isLoad ? (
              <LoadingSpinner />
            ) : (
              <span className="material-symbols-rounded">support_agent</span>
            )}{" "}
          </>
        ) : (
          <span className=" material-symbols-outlined" onClick={closeBot}>
            close
          </span>
        )}
      </button> */}
      {isHide ? (
        <>
          <button
            className="chat-toggle"
            onClick={toggleChatbot}
            style={{ background: primaryColor }}
            title="Chat"
          >
            <span
              className="material-symbols-rounded"
              style={{ color: "white", paddingTop: 3 }}
            >
              forum
            </span>
          </button>
          <button
            className="call-toggle"
            onClick={toggleCall}
            style={{ background: primaryColor }}
            title="Call"
          >
            <span
              className="material-symbols-rounded"
              style={{ color: "white", paddingTop: 3 }}
            >
              call
            </span>
          </button>
        </>
      ) : undefined}
    </div>
  );
};

export default ChatBotWidget;
