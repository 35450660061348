import React ,{ createContext, ReactNode, useContext, useRef, useState } from "react";
import { Registerer, Session, UserAgent } from "sip.js";


export interface ChatContextStateData {
    accessToken: any;
    sipReg: any;
    sipAgent: any; 
    callToken: any;
    customerID:String;
    JwtToken:string;
    isDeleted:boolean;
    threadId:string;
    userAgentRe: UserAgent | null;
  registererRe: Registerer | null;
  sessionRe: Session | null | any;
  extension: {
    Uri: string;
    UA: string;
    Passcode: string;
  };
  socket:WebSocket | null
  JobID:any;
  };

export const ChatContextState: ChatContextStateData = {
    accessToken:null,
    sipReg:null,
    sipAgent:null,
    callToken: null,  
    customerID:'',
    JwtToken:'',
    isDeleted:false,
    threadId:'',
    userAgentRe: null,
  registererRe: null,
  sessionRe: null,
  extension: {
    Uri: "",
    UA: "",
    Passcode: "",
  },
  socket:null,
  JobID:null
  };

  export interface ChatContextActionData {
    setAccessToken: (type:any)=> void,  
    setWebSocket: (type:any)=> void,  
    setsipAgent: (type:any)=> void,
    setsipReg: (type:any)=> void,
    setCallToken: (type:any)=> void,
    setCustomerID: (type:string)=> void,
    setJwtToken: (type:string)=> void
    setIsDeleted: (type:boolean)=> void;
    setThreadId: (type:string)=> void;
    setExtension: (extension: Partial<{ Uri: string; UA: string; Passcode: string }>) => void;
    setJobID: (type:any)=> void
  };

export const ChatContextAction: ChatContextActionData = {
    setAccessToken: () => {}, 
    setWebSocket: () => {}, 
    setsipAgent: () => {},  
    setsipReg: () => {},       
    setCallToken: ()=> {},       
    setCustomerID: () => {},
    setJwtToken: () => {},
    setIsDeleted: () => {},
    setThreadId: () => {},
    setExtension: () => {},
    setJobID: ()=> {}
  };

export const ChatViewContext = createContext(ChatContextState)
export const ChatViewActionContext = createContext(ChatContextAction)

export const ChatViewContextInfo = () => useContext(ChatViewContext)
export const ChatViewActionContextInfo = () => useContext(ChatViewActionContext)
interface ChatContextProviderProps {
  children: ReactNode;
} 

const ChatContextprovider: React.FC<ChatContextProviderProps>= ({ children }) => {
  const [accessToken,setAccessToken] = useState<any>('');
  const [JobID,setJobID] = useState<any>(null);
  const [socket,setWebSocket] = useState<any>(null);
  const [sipAgent,setsipAgent] = useState<any>('');
  const [sipReg,setsipReg] = useState<any>('');
  const [callToken,setCallToken] = useState<any>('');
  const [customerID, setCustomerID] = useState<string>('')
  const [JwtToken, setJwtToken] = useState<string>('')
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  const [threadId, setThreadId] = useState('')
  let userAgentRe = useRef<UserAgent | null>(null);
  let registererRe = useRef<Registerer | null>(null);
  const sessionRe = useRef<Session | null | any>(null);
  const [extension, setExtensionState] = useState<{ Uri: string; UA: string; Passcode: string }>({
    Uri: "",
    UA: "",
    Passcode: "",
  });
  // Function to update the extension object
  const setExtension = (newExtension: Partial<{ Uri: string; UA: string; Passcode: string }>) => {
    setExtensionState((prev) => ({ ...prev, ...newExtension }));
  };
  const contextValue = {
    accessToken,
    sipAgent,
    sipReg,
    customerID,
    JwtToken,
    isDeleted,
    threadId,
    userAgentRe: userAgentRe.current,
    registererRe: registererRe.current,
    sessionRe: sessionRe.current,
    extension,
    callToken,
    socket,
    JobID
  };

    return(
   
        <ChatViewContext.Provider value={contextValue}>
          <ChatViewActionContext.Provider value={
            {
              setAccessToken,
              setsipAgent,
              setsipReg,
              setCustomerID,
              setJwtToken,
              setIsDeleted,
              setThreadId,
              setExtension,
              setCallToken,
              setWebSocket,
              setJobID
            }
          }>
            {children}
          </ChatViewActionContext.Provider>
        </ChatViewContext.Provider>
    )
}

export default ChatContextprovider;